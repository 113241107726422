// Components
import SectionInformationBox from '@/components/ui/SectionInformationBox'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Vuex
import { mapGetters } from 'vuex'
// Utils
import { getPathImage } from '@/utils'

export default {
  name: 'CheckoutFinish',
  components: { SectionInformationBox },
  mixins: [uiMixin],
  computed: {
    ...mapGetters('place', ['placeData'])
  },
  methods: {
    /**
     * Usuario pulsa en el botón de acción
     */
    handleClickButton() {
      // Redirigimos a la sección de dashboard
      this.routerPushTo({
        path: '/plans-guide'
      })
    },
    /**
     * Get path image
     */
    getImage(path, brand = false) {
      return getPathImage(path, brand)
    }
  }
}
